"use strict";

exports.__esModule = true;
exports.HomeSeoMetadata = HomeSeoMetadata;
exports.homeRoute = homeRoute;
exports.HomePath = void 0;

var _createRoute = require("../../../react-static/route/createRoute");

var _SiteConfig = require("../data/SiteConfig");

var HomePath = "/";
exports.HomePath = HomePath;

function HomeSeoMetadata() {
  return {
    description: "The Travel Webway site contains guides, tips and tools for you to make the most of your travel experience using the power of the web.",
    title: _SiteConfig.config.siteTitle + " - " + _SiteConfig.config.siteDescription
  };
}

function homeRoute(rootPath) {
  var title = _SiteConfig.config.siteTitle;
  var route = (0, _createRoute.createRoute)(rootPath, HomePath);

  route.getData = function () {
    return {
      path: HomePath,
      title: title
    };
  };

  return route;
}